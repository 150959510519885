<script lang="ts">
  import TrialRequestForm from "./TrialRequestForm.svelte";
  import Modal from "../common/Modal.svelte";
  import { trialRequestStore } from "../stores";
  import { showToast } from "../toast/toast";
  import { trialRequestMessage } from "../../../utils/constants";
</script>

{#if $trialRequestStore.modalOpen}
  <Modal
    flyOut={$trialRequestStore.submitted}
    title="Request Trials"
    onclose={() =>
      ($trialRequestStore = { modalOpen: false, submitted: false })}
  >
    <TrialRequestForm
      oncancel={() =>
        ($trialRequestStore = { modalOpen: false, submitted: false })}
      onsubmitted={() => {
        $trialRequestStore = { modalOpen: true, submitted: true };
        setTimeout(() => {
          $trialRequestStore = { modalOpen: false, submitted: true };
          showToast(trialRequestMessage);
        }, 500);
      }}
    />
  </Modal>
{/if}
